/* 
    |   *** This config file is the fallback config that will be used when no CONTENT or CONTRACTS ***  |
    |           are set in the .env file. This is the default config for the Decubate UI.               |

    |               Use comments to add extra information to the options if needed.                     |
    |       *** DO NOT remove unused optional keys/variables, comment them out instead ***              |

    | If you want to create a config for a client, copy this file and name it after the client.          |
*/

// Project Content
const _pContent = {
    hasReferral: true,
    burnMechanism: {
        hasBurnMechanism: true,
        burnUrl:
            'https://www.decubate.com/blog/decubate-introduces-dcb-token-burn-mechanics',
    },
    companyName: 'Decubate',
    // optional - comment out when not needed (DO NOT remove)
    metaKeywords:
        'blockchain, cryptocurrency, crypto, incubator, smart vesting, tokenized assets, smart contracts, community rewards, social incubation, easy staking, fair allocation, cross pooling',

    // optional - comment out when not needed (DO NOT remove)
    metaDescription:
        'Decubate token launchpad: Invest early in the future of Web3',
    favicon:
        'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/DCB-favicon.svg',
    logo: {
        mobile: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/decubate-header-logo-mobile-dark.svg',
        desktop:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/decubate-header-logo-desktop-dark.svg',
    },
    web3onboard: {
        logo: 'https://uploads-ssl.webflow.com/623b471f31c24a4a20eb3ca7/6294e95995e10fe9dd8ede4c_DecubateLogo-SVG.svg',
        description:
            'Decubate is the leading prelaunch destination for web3 projects. Get the tools and support you need to complete a successful token launch.',
    },
    privacyPolicy:
        'https://app-eu1.hubspot.com/documents/25442585/view/851500321?accessId=0b9f6f',
    website: 'https://www.decubate.com',
    refundPolicy:
        'https://www.decubate.com/blog/decubate-decentralized-refund-policy',
    termsAndConditions: 'https://eu1.hubs.ly/H06RV150',
    saft: 'https://eu1.hubs.ly/H06RFJ90',
    links: [
        {
            name: 'Apply',
            link: 'https://share-eu1.hsforms.com/1Vmv7E4IJRGehXLzc06xUkgf5bmh',
            position: 'footer',
        },
        {
            name: 'Vote',
            link: 'https://snapshot.org/#/decubategov.eth',
            position: 'header',
        },
        {
            name: 'Tiers',
            link: '/tiers-info',
            position: 'header',
        },
        {
            name: 'Buy DCB',
            position: 'header',
            link: 'https://pancakeswap.finance/swap?outputCurrency=0xeac9873291ddaca754ea5642114151f3035c67a2',
            dropdown_options: [
                {
                    name: 'pancakeswap',
                    link: 'https://pancakeswap.finance/swap?outputCurrency=0xeac9873291ddaca754ea5642114151f3035c67a2',
                },
                {
                    name: 'bitmart',
                    link: 'https://www.bitmart.com/trade/en-US?layout=basic&symbol=DCB_USDT&r=Cd4fry',
                },
                {
                    name: 'mexc',
                    link: 'https://www.mexc.com/exchange/DCB_USDT',
                },
                {
                    name: 'gate',
                    link: 'https://www.gate.io/trade/DCB_USDT',
                },
                {
                    name: 'bitpanda',
                    link: 'https://www.bitpanda.com/en/prices/decubate-dcb',
                },
                {
                    name: 'bingx',
                    link: 'https://bingx.com/en-us/spot/DCBUSDT/',
                },
                {
                    name: 'lbank',
                    link: 'https://www.lbank.com/en-US/trade/dcb_usdt',
                },
                {
                    name: 'bitget',
                    link: 'https://www.bitget.com/spot/DCBUSDT',
                },
                {
                    name: 'coinex',
                    link: 'https://www.coinex.com/exchange/dcb-usdt',
                },
            ],
        },
    ],
    social: [
        { type: 'telegram', url: 'https://t.me/decubate' },
        { type: 'linkedin', url: 'https://www.linkedin.com/company/decubate' },
        { type: 'twitter', url: 'https://twitter.com/decubate' },
        { type: 'medium', url: 'https://decubate.medium.com' },
        { type: 'gitbook', url: 'https://docs.decubate.com' },
        {
            type: 'discord',
            url: 'https://discord.gg/decubate-com',
        },
    ],
    tiers: {
        tierConstants: [
            {
                name: 'Base',
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/tier-logos/BaseTier-Large.svg',
            },
            {
                name: 'Bronze',
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/tier-logos/BronzeTier-Large.svg',
            },
            {
                name: 'Silver',
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/tier-logos/SilverTier-Large.svg',
            },
            {
                name: 'Gold',
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/tier-logos/GoldTier-Large.svg',
            },
            {
                name: 'Platinum',
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/tier-logos/PlatinumTier-Large.svg',
            },
            {
                name: 'Diamond',
                image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/tier-logos/DiamondTier-Large.svg',
            },
        ],
        NoTierImage:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/tier-logos/NoneTier-Large.svg',
    },
    // migrated to innovator hub image managing
    blogUrl: 'https://decubate.medium.com/',
    telegramUrl: 'https://t.me/decubate',
    font: {
        name: 'Roboto',
        url: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap',
    },
    baseImgUrl: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com',
    recaptchaId: '6Lcyin0eAAAAAFLg88k3urC4xRPEKY2YFH5_eETj',
    innovatorUrl: 'https://innovator.decubate.com',
    // migrated to innovator hub image managing
    // blogIcon:
    //     'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/ReadBlog-Icon.svg',
    // migrated to innovator hub image managing
    // investIcon:
    //     'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Invest-DCB-Icon.svg',

    // optional - comment out when not needed (DO NOT remove)
    // hasCenteredHeaderLinks: true,

    // optional - comment out when not needed (DO NOT remove)
    // hasCutProjectCards: false,

    // optional - comment out when not needed (DO NOT remove)
    // EVMIcons: [
    //     'https://cryptologos.cc/logos/bnb-bnb-logo.png?v=026',
    //     'https://cryptologos.cc/logos/tron-trx-logo.png?v=026',
    //     'https://cryptologos.cc/logos/polygon-matic-logo.png?v=026',
    //     'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=026',
    //     'https://cryptologos.cc/logos/arbitrum-arb-logo.png?v=026',
    // ], // links to images

    // optional - comment out when not needed (DO NOT remove)
    // launchpadShowPoweredByLogo: '', // link to image

    // optional - comment out when not needed (DO NOT remove)
    // hasCapsFooter: true,

    // optional - comment out when not needed (DO NOT remove)
    // note: Referral config expects there to be 6 tiers.
    referralConfig: {
        // migrated to innovator hub image managing
        // welcomeExploreImage:
        //     'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/referral/explore-launchpad.webp',
        // migrated to innovator hub image managing
        // welcomeClaimImage:
        //     'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/referral/referral-bonus.webp',
        // migrated to innovator hub image managing
        // headerImage:
        //     'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/referral/Referral-system-banner.jpg',
        referral_text:
            'Invite%20new%20investors%20to%20Decubate%20and%20earn%20unlimited%20rewards',
        // migrated to innovator hub image managing
        // bannerImage:
        //     'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/referral/referral-account-present.webp',
        share_options: [
            { name: 'telegram', url: 'https://t.me/share/url?' },
            { name: 'twitter', url: 'https://twitter.com/share?' },
            { name: 'linkedin', url: 'https://www.linkedin.com' },
        ],
        learnMoreUrl:
            'https://www.decubate.com/blog/unveiling-the-decubate-referral-program',
        rewardModal: {
            // image_urls: indexes should be tier specific.
            image_urls: [
                // 0 base
                'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/referral/base-gift.webp',
                // 1 bronze
                'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/referral/bronze-gift.webp',
                // 2 silver
                'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/referral/silver-gift.webp',
                // 3 gold
                'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/referral/gold-gift.webp',
                // 4 platinum
                'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/referral/platinum-gift.webp',
                // 5 diamond
                'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/referral/diamond-gift.webp',
            ],
        },
    },
    notAnnouncedSquareLogo:
        'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/unannounced-square-logo.svg',
    upcoming_teaser:
        'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/content/not-announced.png',

    tierPageInfo: {
        title: 'Decubate tier system',
        infoParagraphs: [
            'Tiers open up a world of opportunity on Decubate platform. Higher tiers will increase your IDO and Learn to Earn allocations, provide exclusive access and more.',
            'Secure your Decubate Base tier by simply creating a fully verified account. To increase your tier, meet the corresponding staking requirement by staking DCB tokens in one of our staking pools.',
        ],
        findOutMoreUrl: 'https://docs.decubate.com/the-launchpad/tier-system',
        bannerUrl:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Decubate-tiers-chart.webp',
        cardInfo: [],
        benefits: [
            'Invest in highly-vetted web3 projects',
            'Generate DCB rewards',
            'Earn token airdrops by participating in Learn 2 Earn events',
        ],
    },
    accepted_exchanges: [
        {
            name: 'pancakeswap',
            link: 'https://pancakeswap.finance/swap?outputCurrency=0xeac9873291ddaca754ea5642114151f3035c67a2',
        },
        {
            name: 'bitmart',
            link: 'https://www.bitmart.com/trade/en-US?layout=basic&symbol=DCB_USDT&r=Cd4fry',
        },
        {
            name: 'mexc',
            link: 'https://www.mexc.com/exchange/DCB_USDT',
        },
        {
            name: 'gate',
            link: 'https://www.gate.io/trade/DCB_USDT',
        },
        {
            name: 'bitpanda',
            link: 'https://www.bitpanda.com/en/prices/decubate-dcb',
        },
        {
            name: 'bingx',
            link: 'https://bingx.com/en-us/spot/DCBUSDT/',
        },
        {
            name: 'lbank',
            link: 'https://www.lbank.com/en-US/trade/dcb_usdt',
        },
        {
            name: 'bitget',
            link: 'https://www.bitget.com/spot/DCBUSDT',
        },
    ],
    event_page_images: [
        {
            label: 'website',
            image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/event-page-block-images/Website.png',
        },
        {
            label: 'roadmap',
            image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/event-page-block-images/Roadmap.jpg',
        },
        {
            label: 'team',
            image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/event-page-block-images/Team.jpg',
        },
        {
            label: 'tokenomics',
            image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/event-page-block-images/Tokenomics.jpg',
        },
        {
            label: 'participate',
            image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/event-page-block-images/How+to+participate.jpg',
            link: 'https://docs.decubate.com/the-launchpad/getting-started/',
        },
        {
            label: 'buy',
            image: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/event-page-block-images/Buy+DCB.jpg',
        },
    ],
    // hasMobileVideoBtn: false
    // hasGradientStakingChips: false
    stakingBanner: {
        mobile: 'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Decubate-staking-banners/staking-banner-mobile.webp',
        desktop:
            'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Decubate-staking-banners/staking-banner-desktop.webp',
        link: 'https://docs.decubate.com/the-launchpad/tier-system',
    },
    tokenInfoApi: 'https://api.decubate.com/misc/supply',
    dappUrl: 'https://platform.decubate.com',
    gtmId: 'GTM-WZHR9W5',
    launchpadShowPoweredByLogo:
        'https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Decubate-technologies-Logo.svg',
    apply_for_ido_link:
        'https://share-eu1.hsforms.com/1Vmv7E4IJRGehXLzc06xUkgf5bmh',
};

// Project Contracts
const _pContracts = {
    BaseToken: {
        97: {
            symbol: 'DCB',
            decimals: 18,
            address: '0x1c83ec95c2162470a2c2725e9dff6f422d6c4bfe',
        },
        84532: {
            symbol: 'DCB',
            decimals: 18,
            address: '0x522EE541d38De3a33Bd17DF77518DD419c02400F',
        },
    },
    PaymentToken: {
        97: {
            symbol: 'USDT',
            decimals: 18,
            address: '0x180dE5857c4351BfE4DC881EBfceE37E0DC14855',
        },
        84532: {
            symbol: 'USDT',
            decimals: 18,
            address: '0xdfc98830A7dB586a335BF6Cd655FcABb5f902Db8',
        },
    },
    EventFactory: {
        97: '0xB0c5d012029b59C440dF6c52f074881f532901DF',
        84532: '0x88209b7589390C2528dC9DEa87a105b04154F3Ac',
    },
    LegacyEventFactory: {
        97: '0x1A2F5cE0Ad6da39262f901ac8E773d64d3A575Cd',
    },
    Investments: {
        97: '0x77e28CeB560881ca4AdF458f7D350451E84B9F57',
    },
    NFT: '0x64828A2C3D2792ccC2c3553D0F14261C0B48fCBF',
    MultiCall: {
        97: '0x929cEDeaEa128FaDA4E4B2867Dc152E4Ea29DBC0',
        84532: '0x51dB2c19EE4f30709d668144c116416cCa648554',
    },
    Tiers: {
        97: '0x8FBdcEBc2f25351473966F353F5b7e33757Bef84',
        84532: '0xe17361832CdDB0c98fDAE55DF5F6af0B4158420d',
    },
    WalletStore: '0xb5c9eeeFbD14015986B1B1cd9254b750e6907a95',
    TierMigrator: '0x225201A526523293577465D8d3f6c503eA2806a9',

    // Staking
    // Legacy
    StakingV1: '0x5f7014A688662F1C8B3Eee025CC369943b46Df2f',
    LegacyVault: '0xD9FB93FE76f65bAb34BC667F123Af1D50A7c12a3',
    LegacyCompoundStaking: '0xc7a7981FEF557524F993E6DC9419138c52f3f1A5',
    LegacyLiquidityStaking: '0xa873D4E7F49c026AAd52159fF37182b2AAF2ee31',
    MultiAssetStaking: '0xa4dBCd5f7eBEdF3867218A502C908Ad523Db698c',
    // New staking
    Vault: {
        97: '0x6eB32c7ef42aa4E70334d79F5df53470E17288A8',
        84532: '0x5970EFF1018F15Bb755c4CEc1703B3a7ab6E0d4F',
    },
    CompoundStaking: {
        97: '0x2C46C2E447afC54d63889eB1E6a4d75c9EdE3C4a',
        84532: '0xA1fDcE80Fb20af51631A2E97c4D6Bf6e7dfB1BA2',
    },
    LiquidityStaking: { 97: '0x0fB11ec1E2f7936a8DdC1e7Ffa65065F2e01b9d9' },
};

// get the content and contracts from the .env file
const envContent = process.env.REACT_CONTENT;
const envContracts = process.env.REACT_CONTRACT;

// if the .env file has the content and contracts, use them, otherwise use the default
const pContent = envContent ? JSON.parse(envContent) : _pContent;
const pContracts = envContracts ? JSON.parse(envContracts) : _pContracts;

module.exports = { pContent, pContracts };
