import React from 'react';
import { GetStarted } from '@decub8/ui';
import { NextRouter, useRouter } from 'next/router';

import { useAppSelector } from '@src/hooks/index';
import { IdentityVerifiedStatus } from '@src/ts/constants';
import { IContentSection, User } from '@src/ts/interfaces';

import { steps } from '../constants';
import { header_height, scrollIntoView } from '../utils';

const getHandleClick = (
    name: string,
    router: NextRouter,
    events_ref: React.RefObject<HTMLDivElement>,
    user: User,
    wallet_verified: boolean,
) => {
    const has_verified_identity =
        user?.identity_verified === IdentityVerifiedStatus.VERIFIED;

    const has_verified_wallet = !!user?.wallet_address && wallet_verified;

    const identity_pending =
        user?.identity_verified === IdentityVerifiedStatus.PENDING;

    const is_wallet_pending = user?.wallet_address && !wallet_verified;

    switch (name) {
        case 'create_account':
            return () =>
                user ? router.push('/account') : router.push('/login');
        case 'verify_id':
            return () =>
                user
                    ? has_verified_identity || identity_pending
                        ? router.push('/account')
                        : router.push('/verify/identity')
                    : router.push('/login');
        case 'verify_wallet':
            return () =>
                user
                    ? has_verified_wallet || is_wallet_pending
                        ? router.push('/account')
                        : router.push('/verify/wallet')
                    : router.push('/login');
        case 'invest':
            return () => scrollIntoView(events_ref, header_height);
        default:
            return () => {};
    }
};

export const GetStartedSection: React.FC<{
    section: IContentSection;
    className?: string;
    events_ref: React.RefObject<HTMLDivElement>;
}> = ({ section, className, events_ref }) => {
    const router = useRouter();
    const user = useAppSelector((state) => state?.auth.user);
    const { wallet_verified } = useAppSelector((state) => state?.auth) || {};

    const data = steps.map(({ name, title }) => {
        const image_item = section.images.find((image) => image.name === name);

        return {
            title,
            subtitle: image_item?.text || '',
            logo: image_item?.url || '',
            handleClick: getHandleClick(
                name,
                router,
                events_ref,
                user,
                wallet_verified,
            ),
        };
    });

    const props = {
        title: section?.title,
        subtitle: section?.subtitle,
        data,
    };

    return <GetStarted {...props} className={className} />;
};
